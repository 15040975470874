import { formatTime } from "./puzzle-modal";

export function copyLink(button, text){
  try {
    navigator.clipboard.writeText(text).then(() => {
      button.dataset.copied = true;
      setTimeout(() => { button.dataset.copied = false }, 3000)
    });
  } catch (error) {
    console.error('This device does not support writing to the clipboard');
  }
}

export function populateShareTemplate(element, text, url) {
  return element.getAttribute('data-share-template')
    .replace('${url}', url)
    .replace('${text}', text);
}

export function setModalShareText(modal, time, url) {
  const shareText = `I completed this in ${formatTime(time)}, can you beat me?`;

  modal.querySelectorAll('a[data-share-template]').forEach((link) => {
    link.href = populateShareTemplate(link, shareText, url);
  });

  const copyLinkButton = modal.querySelector('button[data-share-template]');
  copyLinkButton.dataset.shareText = populateShareTemplate(copyLinkButton, shareText, url);
}